import anylogger from '@app/anylogger'
import { compare, Operation } from '@app/jsonpatch'
import { AddTo, isNum, toString } from './StrUtils'
import { IJsonValueProvider, JsonValueProvider, JVPInit } from './JsonValueProvider'
import { IJsonTransform, IJsonTransformList, JsonTransformFromJsonValueProviders } from './JsonTransform'

const log = anylogger('JsonObjectAdapter')

export interface IJsonAdapter<S extends object = any, D extends object = any> {
	adapt(src: S): D
	adaptBack?(src: D): S
}

export type JsonAdapterInit<S extends object = any, D extends object = any> =
	| IJsonTransform<S, D>
	| [JVPInit<S>]
	| [JVPInit<S>, JVPInit<D>]
	| [JVPInit<S>, JVPInit<D>, JsonAdapterInit[] | IJsonAdapter<S, D>]
export type JsonAdapterInitList<S extends object = {}, D extends object = {}> = JsonAdapterInit<S, D>[]

/**
 * This class is used to adapt one JSON object to another.
 * It is constructed with a an array of {@link JsonAdapterInit} configurations.
 * The array of JsonAdapterInit configurations are transformed into an array of IJsonTransforms.  Each transform converts one or more properties of the src object to the dst object.
 * Each {@link JsonAdapterInit} item must be either an {@link IJsonTransform} instance or an array with the following structures:
 * * If the array item is an IJsonTransform, it will responsible for performing the transform and a JVPInit/JsonValueProvider will not be used.  This can be used to perform custom transforms, such as merging fields, adding default values or altering the data.
 * * A single {@link JVPInit} configuration.  In this case, a single {@link JsonValueProvider} instance is created and used for both the src and dst objects.
 * * Two {@link JVPInit} configurations.  In this case the first one transforms values from the src object, and the second one transform values from the dst object.
 * * If three items are specified, the first two are {@link JVPInit} configurations for the src and dst as specified above.
 * The third specifies a JsonAdapter that is used to adapt either an object, or an array that is specified by the JVPInit values.
 * It can either be a JsonAdapterInit array, or an instance of {@link IJsonAdapter}.  This variation can be used to recursively adapt sub-objects or arrays of the main object.
 * */
export class JsonAdapter<S extends object = any, D extends object = any> {
	transforms: IJsonTransformList<S, D> = []

	constructor(transformTypes?: JsonAdapterInitList<S, D>) {
		this.addTransforms(transformTypes)
	}

	addTransforms(transformTypes?: JsonAdapterInit<S, D>[]) {
		if (!transformTypes) throw new Error(`You must specify transforms when creating a JsonAdapter`)

		const test = transformTypes.map((t) => {
			// if (typeof t == 'string') {
			// 	return new JsonTransformFromJsonValueProviders(t, t) as IJsonTransform<S, D>
			// } else
			if (Array.isArray(t)) {
				if (t.length == 1) return new JsonTransformFromJsonValueProviders<S, D>(t[0], t[0]) as IJsonTransform<S, D>
				else if (t.length == 2) return new JsonTransformFromJsonValueProviders(t[0], t[1]) as IJsonTransform<S, D>
				else if (t.length == 3)
					if (Array.isArray(t[2]))
						return new JsonTransformFromJsonValueProviders(t[0], t[1], new JsonAdapter(t[2])) as IJsonTransform<S, D>
					else return new JsonTransformFromJsonValueProviders(t[0], t[1], t[2])
				else throw new Error(`JsonAdapter initializer array items can only have one or two entries`)
			} else if (typeof t == 'object') {
				// this must be an IJsonTransform
				return t
			} else {
				// this will cause a TS linting error if we missed any combinations, but does not prove the above logic is correct
				const val: never = t
				throw new Error(`Invalid JSonAdapter initialization`)
			}
		})
		this.transforms = test
	}
	adapt(src: S | S[]): D | D[] {
		if (typeof src == 'undefined') return []
		if (Array.isArray(src)) return this.adaptList(src)
		else if (typeof src == 'object') return this.adaptItem(src)
		else throw new Error(`We seem to have a situation here...  src is not an array. It is of type '${typeof src}': ${src}`)
	}
	adaptBack(src: D | D[]): S | S[] {
		if (typeof src == 'undefined') return []
		if (Array.isArray(src)) return this.adaptListBack(src)
		else if (typeof src == 'object') return this.adaptItemBack(src)
		else throw new Error(`We seem to have a situation here...  src is not an array. It is of type '${typeof src}': ${src}`)
	}

	private adaptList(src: S[]): D[] {
		return src
			.map((item) => {
				return this.adapt(item)
			})
			.filter(Boolean) as D[]
	}
	adaptItem(src: S): D {
		const item = this.transforms.reduce((res: D, t) => {
			t.transform(src, res)
			return res
		}, {} as D)
		log('item', item)
		return item
	}

	private adaptListBack(src: D[]): S[] {
		return src
			.map((item) => {
				return this.adaptBack(item)
			})
			.filter(Boolean) as S[]
	}
	adaptItemBack(src: D): S {
		return this.transforms.reduce((res: S, t) => {
			if (t.transformBack) t.transformBack(src, res)
			return res
		}, {} as S)
	}
}
